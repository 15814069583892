export const CLOSE = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.10007 0.824951L0.825073 3.09995L8.77507 11L0.825073 18.9L3.10007 21.1749L11.0501 13.275L19.0001 21.1749L21.2501 18.9L13.3251 11L21.2501 3.09995L19.0001 0.824951L11.0501 8.72495L3.10007 0.824951Z" fill="#979797"/>
</svg>
)
export const CLOUDS = 'https://firebasestorage.googleapis.com/v0/b/saras-project-299723.appspot.com/o/clouds.jpeg?alt=media&token=9c5359a3-fe8d-4b51-ae09-f69c5a972a70';
export const DOWNLOAD = 'https://firebasestorage.googleapis.com/v0/b/saras-project-299723.appspot.com/o/download.png?alt=media&token=89de51be-f102-4c94-b41d-999f2f4d6985';
export const LOGO_NEW = 'https://firebasestorage.googleapis.com/v0/b/saras-project-299723.appspot.com/o/logo_new.png?alt=media&token=72b2462d-0362-4e77-8cf6-8156c4115bb0';
export const PLAY = 'https://firebasestorage.googleapis.com/v0/b/saras-project-299723.appspot.com/o/play.png?alt=media&token=034d2686-6a37-4bca-a41c-f110ee2b6cbd';
export const BACK_ARROW = (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 5.92969L6.92969 12L13 18.0703L14.5 16.5703L9.92969 12L14.5 7.42969L13 5.92969Z" fill="#5AC8FA"/>
</svg>
)
export const PDF = (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 28.125H5V1.875H18.75L25 8.125V28.125Z" fill="#FF5722"/>
<path d="M24.0625 8.75H18.125V2.8125L24.0625 8.75Z" fill="#FBE9E7"/>
<path d="M10 24.375C9.75004 24.375 9.56254 24.3125 9.37504 24.25C8.68754 23.875 8.62504 23.3125 8.75004 22.875C9.00004 22.125 10.375 21.1875 12.1875 20.375C13 18.875 13.625 17.3125 14 16C13.375 14.8125 13.0625 13.6875 13.0625 12.875C13.0625 12.4375 13.1875 12.0625 13.375 11.75C13.625 11.4375 14 11.25 14.5 11.25C15.0625 11.25 15.5 11.5625 15.6875 12.125C16 12.875 15.8125 14.25 15.375 15.8125C16 16.875 16.75 17.875 17.5625 18.625C18.75 18.375 19.8125 18.25 20.5 18.375C21.6875 18.5625 21.875 19.375 21.875 19.6875C21.875 21 20.5 21 20 21C19.0625 21 18.125 20.625 17.3125 19.9375C15.8125 20.3125 14.3125 20.8125 13.125 21.375C12.5 22.4375 11.875 23.3125 11.3125 23.8125C10.75 24.25 10.3125 24.375 10 24.375ZM10.75 22.5625C10.4375 22.75 10.1875 22.9375 10.0625 23.125C10.1875 23.0625 10.4375 22.9375 10.75 22.5625ZM19.25 19.625C19.5 19.6875 19.75 19.75 20 19.75C20.375 19.75 20.5625 19.6875 20.625 19.6875C20.5625 19.625 20.125 19.5 19.25 19.625ZM14.875 17.375C14.625 18.125 14.25 18.9375 13.9375 19.6875C14.6875 19.4375 15.4375 19.1875 16.1875 19C15.6875 18.5 15.25 17.9375 14.875 17.375ZM14.5 12.5C14.4375 12.5 14.4375 12.5 14.4375 12.5C14.375 12.5625 14.3125 13 14.5625 13.9375C14.625 13.1875 14.625 12.625 14.5 12.5Z" fill="white"/>
</svg>
)