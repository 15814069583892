import React from 'react';
import styles from './FamilyPodcast.module.css';
import {Layer, Box} from 'grommet';
import AWS from 'aws-sdk';
import emailjs from 'emailjs-com';
import moment from 'moment';
import GoogleLogin from 'react-google-login';
import { init } from 'emailjs-com';
import * as staticImages from '../staticImages';
import CookieService from '../CookieService';

class FamilyPocast extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popup: '',
            descriptionPlaceholder: "Send some anonymous question(s) or give some feedback!",
            desc_editing: false,
            q_forPlaceholder: "Who is this question or suggestion for?",
            q_for_editing: false,
            podcasts: [],
            listFiles: [],
            tries: 0,
            sara: false, //Admin mode
            google: false,
            day: moment().format('DD'),
            month: moment().format('MM'),
            year: moment().format('YYYY'),
            names: [],
            validCookie: false,
            aboutOrSchedule: '',
        }

        this.download = this.download.bind(this);
        this.submitQ = this.submitQ.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.loadAndInit = this.loadAndInit.bind(this);
        this.onSignIn = this.onSignIn.bind(this);
        this.handleAdminChanges = this.handleAdminChanges.bind(this);
        this.create_blob = this.create_blob.bind(this);
    }

    async create_blob(file, callback) {
        var reader = new FileReader();
        reader.onload = function() { callback(reader.result) };
        reader.readAsDataURL(file);
    }

    async handleAdminChanges() {
        // first check if any rules were broken. All null for a group is ok, all true for a group is needed
        let podcastFile, podcastName, month, day, year, upNext, coming1, coming2, coming3, deleteKey, errorMsg = '', ignorePodcast, ignoreUpcoming, ignoreDeletion;
        try {   
            podcastFile = document.getElementById('podcastNew').value;
            podcastName = document.getElementById('podcastName').value;
            month = document.getElementById('MM').value ? document.getElementById('MM').value : this.state.month;
            day = document.getElementById('DD').value ? document.getElementById('DD').value : this.state.day;
            year = document.getElementById('YYYY').value ? document.getElementById('YYYY').value : this.state.year;

            let allNull = !podcastFile && !podcastName,// && !month && !day && !year,
                allTrue = podcastFile && podcastName && month && day && year && month.length < 3 && day.length < 3 && (year.length === 4 || year.length === 2);
                if (month && month[0] === '0') {
                    month = month[1];
                }
                if (day && day[0] === '0') {
                    day = day[1];
                }
                if (year.length === 2) {
                    year = "20"+year;
                }
            if (!allNull && !allTrue) {
                console.log(podcastFile, podcastName, month, day, year, this.state)
                errorMsg += "Something doesn't seem right. Check sections 1-3, the info about a podcast you are uploading. ";
            }
            ignorePodcast = allNull;


            upNext = document.getElementById('upNext').value;
            coming1 = document.getElementById('comingUp1').value;
            coming2 = document.getElementById('comingUp2').value;
            coming3 = document.getElementById('comingUp3').value;

            allNull = !upNext && !coming1 && !coming2 && !coming3;
            allTrue = upNext && coming1 && coming2 && coming3;
            if (!allNull && !allTrue) {
                errorMsg += "Check section 4 about upcoming podcasts. If you only have a few planned, just put a space in the unplanned slots for now. ";
            }

            ignoreUpcoming = allNull;

            deleteKey = document.getElementById('deletion').value;

            ignoreDeletion = !deleteKey;
            if (deleteKey && !deleteKey.includes('visible')) {
                errorMsg += 'If you want to remove a podcast from the site, copy the full path for it including "visible/". '
            }

        } catch(e) {console.log(e)}

        if (errorMsg || (ignorePodcast && ignoreDeletion && ignoreUpcoming)) {
            // set message and return
            try {
                document.getElementById('p_warning_admin').innerText = errorMsg;
                window.scrollTo(0, 0);
                return;
            } catch {}
        }

        // send receipt of transactions
        let receipt = `Hi! You've updated the site. Here's what we got from you: `;

        // clear error message
        try {
            document.getElementById('p_warning_admin').innerText = '';
        } catch {}

        // then figure out what steps to take
        if (!ignorePodcast && podcastFile) {
            // upload a podcast

            // create key
            let key = `visible/${podcastName}_${year}-${month}-${day}.mp3`
            receipt += `${podcastName}'s podcast: ${podcastName}_${year}-${month}-${day}.mp3. `

            fetch('https://83phlj5pva.execute-api.us-east-1.amazonaws.com/beta/gets3puturl',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({password: this.state.login, key, validCookie: this.state.validCookie}),
            })
            .then(result=>{
                if (result && result.ok) {
                    return result.json()
                }
                return result.text().then(text => {throw new Error(text)})
            })
            .then(data=>{
                fetch(JSON.parse(data.body).signedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'audio/mp3',
                    },
                    body: this.state.fileData,
            })})
            .catch(err=>{
                console.log("Error! Could not submit request for mp3 upload " + err);
            })

        }

        if (!ignoreUpcoming) {
            // update ddb table with new names
            let names = JSON.stringify({"UpNext": upNext, "ComingUp1": coming1, "ComingUp2": coming2, "ComingUp3": coming3})
            receipt += ` Who's up next: ${names}. `
            fetch('https://83phlj5pva.execute-api.us-east-1.amazonaws.com/beta/updateschedule', 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({password: this.state.login, validCookie: this.state.validCookie, names: {"UpNext": upNext, "ComingUp1": coming1, "ComingUp2": coming2, "ComingUp3": coming3},}),
                }).then(res=>{
                    if (!res.ok) {
                        return res.text().then(text => {throw new Error(text)});
                    }
                    this.setState({names: [upNext, coming1, coming2, coming3]})
                }).catch(err=>{console.log('error! ' + err)})

        }

        if (!ignoreDeletion) {
            receipt += `A deletion for ${deleteKey}. `
            // delete s3 object (will need to call api route)
            fetch('https://83phlj5pva.execute-api.us-east-1.amazonaws.com/beta/hidepodcast', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    remove: deleteKey,
                    password: this.state.login,
                })
                })
                .then(response=>{
                    if (!response.ok) {
                        return response.text().then(text => {throw new Error(text)});
                    }
                })
                .catch(err=>{
                    console.log('error: could not delete or move object ' + err);
                })
        }
        receipt += `You should see these changes be reflected on the site in a few minutes.${ignorePodcast ? "" : " You'll get another email when the podcast is ready to view."}`;
        console.log(receipt)
        // display loading sign when necessary
        emailjs.send('family_podcast','template_b39g4i1', {from_name: 'CasalettoConversations_SelfService_Receipt', message: receipt,})
        document.getElementById('loading1').style.display = '';
        document.getElementById('loading2').style.display = '';

        // When done, take back to normal site
        var time = 10;
        
        document.getElementById('submit_admin').style.pointerEvents = 'none'
        let interval = setInterval(()=>{document.getElementById('submit_admin').innerText = `redirect in: ${time--}`}, 1000); 
        setTimeout(()=>{clearInterval(interval); this.setState({sara: false, google: false, password: true,}, ()=> setTimeout(()=>this.forceUpdate(), 2))}, 10000);
    }

     onSignIn(googleUser) {
        // Useful data for your client-side scripts
        var profile = googleUser.getBasicProfile();
        console.log(profile.getName())
        if (profile.getName() !== 'Ben Finkelstein' && profile.getName() !== 'Sara Casaletto') {
            this.setState({sara: false});
            this.forceUpdate()
        } else {
            this.setState({google: true});
        }
     }

    loadAndInit(data, login) {
        data = JSON.parse(data.body);
        if (!data) return;

        setTimeout(()=>this.forceUpdate(), 2);
        this.setState({password: true, tries: 0, podcastNames: data.list, bucket: data.bucket, login, })
        try {
            document.getElementById('p_warning').innerText = ''
            document.getElementById('p_hint').innerText = ''
        } catch{}

        let podcasts = [];

        for (let fileIndex in data.list) {
            let file = data.list[fileIndex];
            let yyyy        =   file.substring(file.indexOf('_') + 1, file.indexOf('-'))
            let mm_dash_dd  =   file.substring(file.indexOf('-') + 1, file.indexOf('.mp3'));
            let mm          =   mm_dash_dd.substring(0, mm_dash_dd.indexOf('-'));
            let dd          =   mm_dash_dd.substring(mm_dash_dd.indexOf('-')+1);
            let name        =   file.substring(8, file.indexOf('_')) + ' - ' + mm + '/' + dd + '/' + yyyy;
            podcasts.push({name, link: data.signedList[fileIndex], key: file})
        }

        this.setState({podcasts, sara: data.sara, listFiles: data.list, pdf: data.pdf, cdn: data.cdn,});
    }

    checkPassword() {
        try {
            document.getElementById('login').innerText = 'Signing you in...'
            var password = document.getElementById('password').value;
         } catch{}
        

        let names;
        fetch('https://83phlj5pva.execute-api.us-east-1.amazonaws.com/beta/getschedule', {
                method: 'GET',
                })
                .then(response=>{
                    if (!response.ok) {
                        return response.text().then(text => {throw new Error(text)});
                    } return response.json();
                })
                .then(tableNames=>{
                    names=JSON.parse(tableNames.body).listofnames;

                    this.setState({names,});
                })
                .catch(err=>{
                    console.log('error: could not delete or move object ' + err);
                })


        fetch('https://83phlj5pva.execute-api.us-east-1.amazonaws.com/beta/listpodcasts', 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },

            // warning: validCookie may be a slight vulnerability
            body: JSON.stringify({password, validCookie: this.state.validCookie}),
        })
            .then(result=>{
                if (result && result.ok) {
                    // Initialize the Amazon Cognito credentials provider
                    AWS.config.region = 'us-east-1'; // Region
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: 'us-east-1:293084d9-60fb-40f5-a81c-af69edd85654',
                        apiVersion: '2014-06-30',
                    });

                    // Make the call to obtain credentials
                    AWS.config.credentials.get();
                    return result.json()
                }
                return result.text().then(text => {throw new Error(text)})
            })

            .then((data) => {
                let date = new Date();
                date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
                const options = {path: '/', expires: date};
                CookieService.remove('session_expiration');
                CookieService.set('session_expiration', "", options);
                this.loadAndInit(data, password);
            })

            .catch(error => {
                try {
                    console.log(error)
                    document.getElementById('login').innerText = 'Login'
                if (this.state.tries < 3) {
                    document.getElementById('p_warning').innerText = 'Error: Wrong password. Try again!'
                } else {
                    document.getElementById('p_warning').innerText = 'Error: Wrong password. Try again!'
                    document.getElementById('p_hint').innerText = 'Hint: Name of the "Resort"'
                }
            } catch{}
                this.setState({tries: this.state.tries + 1});
            })

    }

    submitQ(submitting=false) {

        if (submitting) {
            var name, questions;
            try {
                name = document.getElementById('for').value;
                questions = document.getElementById('desc').value;
            } catch {}

            if (name && questions) {
                document.getElementById('warning').innerHTML = '';
                emailjs.send('family_podcast','template_b39g4i1', {from_name: name, message: questions,})
                    .then(function(response) {
                    //console.log('SUCCESS!', response.status, response.text);
                    }, function(err) {
                    //console.log('FAILED...', err);
                    });
                this.setState({popup: ''});
            } else {
                document.getElementById('warning').innerHTML = 'Must fill out both boxes before submitting!';
            }
        } else {
            document.getElementById('warning').innerHTML = '';
            this.setState({popup: ''});
        }
    }

    componentDidMount() {
        // for emailJs
        init("user_sMgBq4LTeD4ijE1vDXECk");

        // check for valid cookie
        let cookie = CookieService.get('session_expiration');
        // eslint-disable-next-line
        if (cookie != undefined) {
            this.setState({validCookie: true})
        }
}

    componentDidUpdate(prevProps) {
        if (prevProps.height !== this.props.height || prevProps.width !== this.props.width) {
            this.forceUpdate();
        }
        document.getElementById('body').style.overflow = 'scroll !important'
        if (document.getElementById('body').style.overflow !== 'scroll') {
            
        }

    }

    download(link, name, key, pdf) {

        fetch('https://83phlj5pva.execute-api.us-east-1.amazonaws.com/beta/listpodcasts', 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({password: this.state.login, download_key: key, validCookie: this.state.validCookie, pdf}),
        })
            .then(result=>{
                if (result && result.ok) {
                    return result.json()
                }
                return result.text().then(text => {throw new Error(text)})
            })

            .then((data) => {
                data = JSON.parse(data.body);
                var hiddenElement = document.createElement('a');
                hiddenElement.href = data.signedURL;
                // hiddenElement.target = '_blank';
                hiddenElement.download = name;
                hiddenElement.click();
            })

            .catch(error => {
                console.log('error downloading! ' + error)
                alert('error' + JSON.stringify(error))
            })
    }

    render() {
        var {descriptionPlaceholder, q_forPlaceholder, names, aboutOrSchedule} = this.state;
        names = names ?? [];
        var mr = Math.max(3, (100 + (this.props.width - 1500)/5));
        var q_m = Math.max(3, (60 + (this.props.height - 830)/5));
        var titleSize = Math.max(0, Math.min(60, (60 + (this.props.width- 1100)/15) ));
        var titleLeft = window.innerWidth < 1200 ? 130 : 0;
        
        var squish = Math.max(-27, Math.min(0, (this.props.height- 810)/8));
        squish = this.props.renderMobile? 0 : squish;

        var question_squish = Math.max(16, Math.min(60, 60 + (this.props.height - 866)/5));

        var schedule = document.getElementById('schedule');
        var temp = schedule? window.getComputedStyle(schedule).width : '0px';
        var width = `${2*parseInt(temp.substring(0, temp.length - 2)) + mr + 20}px`;
        width = this.props.renderMobile ? '320px' : width;

        let leftCol = document.getElementById('leftCol')
        if (leftCol) {
            var marginRight = window.getComputedStyle(leftCol).getPropertyValue('margin-right')
            var announcementsTop = `calc(60% + ${marginRight.substring(0, marginRight.length - 2) - 20}px)`
        }

        if (!this.state.password) {
            return (
                <Layer style={{background: null}} responsive={true}>
                    <Box 
                    background="#F6F7F9" 
                    round="25px" 
                    height= {`${window.innerHeight <= 750 && !this.props.renderMobile ? window.innerHeight - 300 : 450}px`} 
                    width={this.props.renderMobile? `${Math.min(window.innerWidth, 500)}px` : '500px'}
                    style={{borderRadius: '15px', minHeight: '300px'}}
                    >

                        <Box width={this.props.renderMobile? `${window.innerWidth}px` : '500px'} height="100%" style={{overflowY: "scroll", position: "relative",}}>
                            <div className={styles.fair_txt} style={{left: this.props.renderMobile? 'auto' : '', width: this.props.renderMobile? '100%' : '', textAlign: this.props.renderMobile? 'center' : '', fontSize: this.state.validCookie ? '40px' : ''}}>{`${this.state.validCookie ? `Welcome back!` : `Password`}`}</div>
                            <div className={styles.icon_box} style={{display: this.props.renderMobile? 'none' : ''}}>
                                <img className={styles.icon} alt="" src={staticImages.PLAY}/>
                            </div>
                            <div className={styles.desc} style={{left: this.props.renderMobile? 'auto' : '', width: this.props.renderMobile? '100%' : '', textAlign: this.props.renderMobile? 'center' : ''}}>{`${this.state.validCookie ? `Looks like you've been on the site in the last 2 weeks. Welcome back! Click the login button to see the site.`:`Enter the password to see the site!`}`}</div>
                            { this.state.validCookie ? null :
                                <input wrap="hard" id='password' style={{width: '337px', height: '14px', left: this.props.renderMobile ? 'calc(50% - 180.5px)' : '70px', top: 'calc(50% + 50px)', fontSize: this.props.renderMobile ? '16px' : ''}} onKeyUp={(e)=>{if(e.keyCode === 13) {e.preventDefault(); this.checkPassword()}}} className={styles.popup_input} type='text' placeholder="Enter the password here"/>
                            }
                            <div id='login' className={styles.save} onClick={()=>this.checkPassword()}>Login</div>
                            <div id='p_warning' className={styles.p_warning}></div>
                            <div id='p_hint' style={{top: 'calc(50% + 15px'}} className={styles.p_warning}></div>
                        </Box>

                    </Box>
                    <div id='submit_admin' style={{display: 'none'}}></div>
                </Layer>
            );
        }

        if (this.state.sara && !this.state.google) {
            return (
                <div style={{height: `${this.props.height}px`, width: `${this.props.width}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                    <p>Please Authenticate through Google</p>
                    <div style={{position: 'relative', left: '60px'}}>
                    <GoogleLogin
                        clientId="170854822770-ljlle4jq6lfrga3pl20m19a2l7oepd6r.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={this.onSignIn}
                        onFailure={this.onSignIn}
                        cookiePolicy={'single_host_origin'}
                    />
                    </div>
                    <div id='submit_admin' style={{display: 'none'}}></div>
                </div>
            )
        }
        if (this.state.sara && this.state.google) {
            return (
                        <div className={styles.scroll}>
                        {/* <div style={{position: 'relative', maxWidth: '100px', overflowX: 'hidden'}}> */}
                            <div className={styles.adminTxt} style={{left: this.props.renderMobile? 'auto' : '', width: this.props.renderMobile? '100%' : '', textAlign: this.props.renderMobile? 'center' : ''}}>👸 Admin 👸</div>
                            <div className={styles.welcomeSara} style={{left: this.props.renderMobile? 'auto' : '', width: this.props.renderMobile? '100%' : '', textAlign: this.props.renderMobile? 'center' : ''}}>Welcome, Sara</div>

                            <div className={styles.adminGrid}>

                            <p style={{gridColumnStart: 1, gridColumnEnd: 4}}><strong>General rules:</strong>&nbsp; Sections 1-3 should be done together, section 4 can be submitted by itself, and same with section 5. Or mix and match between those 3 groups. If there was an error in your submission, then you will see a red error message appear right above this text. If no error were detected in your submission, then you will be taken to the main webpage after 10 seconds. Submitting a podcast may take a few minutes to update on the site. When a submission has finished, you will receive an email (mostly of jibberish), and this lets you know the upload has been successful. After that, you can refresh the site and view your changes.</p>
                            <div className={styles.fakeLink} style={{marginBottom: '30px', marginLeft: '20px'}} onClick={(e)=>{e.preventDefault();this.setState({sara: false, google: false, password: true}); setTimeout(()=>this.forceUpdate(), 2)}}>Go to Main Site</div>

                            <div style={{gridColumnStart: 1, gridColumnEnd: 4}}>
                                <p>1. Pick a file to upload. The name of the file can be anything, as long as it ends in ".mp3"!</p>
                                <input accept=".mp3" wrap="hard" id='podcastNew' onChange={(e)=>{this.setState({fileData: e.target.files[0]})}}  style={{position: 'relative', left: "20px"}} type='file' placeholder=""/>
                            </div>
                            <div style={{gridColumnStart: 1, gridColumnEnd: 4}}>
                                <p>2. Whose podcast are you uploading? Just include the first name, and capitalize the first letter please :)</p>
                                <input wrap="hard" id='podcastName'  className={styles.popup_input_grid} type='text' placeholder="This week featured..."/>
                                <p>3. If this podcast should have an upload date other than today, edit the boxes below (MM DD YYYY). If left blank, today's date will be implied.</p>
                            </div>
                            <div style={{gridColumnStart: 1, gridColumnEnd: 2, display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                                <p style={{margin: '0', left: '20px', marginRight: '20px'}}>Month</p>
                                <input wrap="hard" id='MM' style={{width: '80px'}}  className={styles.popup_input_grid} type='number' placeholder={this.state.month}/>
                            </div>
                            <div style={{gridColumnStart: 2, gridColumnEnd: 3, display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                                <p style={{margin: '0'}}>Day</p>
                                <input wrap="hard" id='DD' style={{width: '80px'}}  className={styles.popup_input_grid} type='number' placeholder={this.state.day}/>
                            </div>
                            <div style={{gridColumnStart: 3, gridColumnEnd: 4, display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                                <p style={{margin: '0'}}>Year</p>
                                <input wrap="hard" id='YYYY' style={{width: '80px'}}  className={styles.popup_input_grid} type='number' placeholder={this.state.year}/>
                            </div>
                            <div style={{gridRowStart: 6, gridRowEnd: 6, gridColumnStart: 1, gridColumnEnd: 4, marginTop: '10px'}}>
                            <p>4. Who's coming up? List left to right (soonest on the left)</p>
                                    <input wrap="hard" id='upNext' style={{width: '100px'}}  className={styles.popup_input_grid} type='text' placeholder="Up Next"/>
                                    <input wrap="hard" id='comingUp1' style={{width: '100px'}}   className={styles.popup_input_grid} type='text' placeholder="Coming Up 1"/>
                                    <input wrap="hard" id='comingUp2' style={{width: '100px'}}   className={styles.popup_input_grid} type='text' placeholder="Coming Up 2"/>
                                    <input wrap="hard" id='comingUp3' style={{width: '100px'}}   className={styles.popup_input_grid} type='text' placeholder="Coming Up 3"/>
                            </div>
                            <div style={{gridRowStart: 7, gridRowEnd: 8, gridColumnStart: 1, gridColumnEnd: 4}}>
                                <p style={{marginBottom: '0px'}}>5. (Optional) To delete someone, put their filename here (see list of files below the submit button, then copy and paste 1 filename to delete). This won't actually delete the file. It just moves it into a hidden folder.</p><br></br>
                                <input wrap="hard" id='deletion'  className={styles.popup_input_grid} type='text' placeholder="(Optional) Deletion. Copy and paste 1 file path from below to remove it from the site if desired."/>
                            </div>
                            <div style={{gridRowStart: 9, gridRowEnd: 10, gridColumnStart: 1, gridColumnEnd: 4, marginTop: '10px'}}>
                            <div id='submit_admin' className={styles.save} style={{position: 'relative', marginTop: '30px', top: 'auto'}} onClick={()=>this.handleAdminChanges()}>Submit</div>
                            </div>
                            <div style={{gridRowStart: 11, gridRowEnd: 11, gridColumnStart: 1, gridColumnEnd: 4, dislpay: 'flex', justifyContent:'center'}}>
                            <h3 style={{width: '50%', left: '0px', position: 'relative', }}>Current Files (full paths):</h3>
                            <ul>
                            {
                                (this.state.listFiles ?? []).map(file=>{
                                    return (
                                        <div key={file}>
                                        <li>{file}</li>
                                        <br/>
                                        </div>
                                    )
                                })
                            }
                            </ul>
                            </div>
                            </div>
                            <div id='p_warning_admin' className={styles.p_warning} style={{top: '153px', fontSize: '18px'}} onClick={()=>{}}></div>
                            <div style={{'position':'relative', height: '200px', width: '200px'}}>
                                <iframe title='loading1' id='loading1' src="https://giphy.com/embed/xUA7b8HnXheyI13tiU" width="100" height="100" frameBorder="0" className="giphy-embed" style={{display: 'none', pointerEvents:'none'}}></iframe>
                            </div>
                            <div style={{'position':'absolute', height: '200px', width: '200px', right: '0px', top: '80%'}}>
                                <iframe title='loading2' id='loading2' src="https://giphy.com/embed/xUA7b8HnXheyI13tiU" width="100" height="100" frameBorder="0" className="giphy-embed" style={{display: 'none', pointerEvents:'none'}}></iframe>
                            </div>
                    </div>
            );
        }
        return (
            <>
             {this.props.renderMobile?

            <div style={{overflow: this.props.renderMobile ? 'scroll' : 'hidden',}}>
                <div className={styles.title} style={{left: this.props.renderMobile? '' : `${titleLeft}px`, fontSize: this.props.renderMobile? `${Math.max(25, titleSize)}px` : `${titleSize}px`, width: this.props.renderMobile? '100%' : `${this.props.width - titleLeft}px`, zIndex: this.props.renderMobile? 5 : '', top: this.props.renderMobile? '43px' : '' }}>Casaletto Conversations</div>
                <img className={styles.mountains} style={{position: 'absolute', top: '0px', width: `${this.props.width}px`, height: `${this.props.height}px`, display: this.props.renderMobile? 'none' : ''}}  alt="clouds" src={staticImages.CLOUDS} />
                <img className={styles.logo} alt="logo" src={staticImages.LOGO_NEW} style={{zIndex: this.props.renderMobile? 5 : '', top: this.props.renderMobile? 'auto' : '', display: this.props.renderMobile? 'none' : ''}}/>
                
                <div className={this.props.renderMobile? styles.mainGrid : styles.flexContainer}>
                    {
                        this.props.renderMobile?  <div/> : null
                    }
                    <div className={styles.emptyCol} style={{marginRight: `${mr + 20}px`, left: this.props.renderMobile? 'auto' : '0px', background: 'none',}}>
                        
                        <div className={styles.subCol} style={{top: '0px', height: this.props.renderMobile? '310px' : '100%',}}>
                            <div className={styles.subtitle} >About</div>
                            <div className={styles.about} style={{lineHeight: '24px', height: 'calc(100% - 70px)', overflow: 'scroll'}}>This website gives all the information for our Family Podcast. The purpose of this podcast is to bring our family together and keep everyone updated while we cannot see one another during this pandemic. These episodes have been recorded and edited by Sara Casaletto.</div>
                        </div>
                        
                    </div>

                    <div className={styles.subCol} style={{top: `calc(60% + ${q_m}px)`, left: this.props.renderMobile? 'auto' : '0px', width, bottom: this.props.renderMobile? '-100px' : '0px', display: this.props.renderMobile? 'none' : '' , marginRight: `${mr + 20}px`,}}>
                            <div className={styles.subtitle} style={{top: '16px'}}>Questions & Suggestions</div>
                            <div style={{position: 'absolute', top: '50px', left: '0px', width: '100%', height: 'calc(100% - 60px)', overflow: 'scroll',}}>
                                <div className={styles.q_container} style={{width, gridRowGap: `${question_squish}px`}}>
                                    <div className={styles.about_question} >Press the button below to submit a question for the upcoming podcast!</div>
                                    <div className={styles.button_container}>
                                        <div className={styles.question} onClick={()=>{this.setState({popup: 'question'})}}>Ask a Question!</div>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div id='schedule' className={styles.emptyCol} style={{marginRight: `${mr + 20}px`, left: this.props.renderMobile? 'auto' : '0px', background: 'none',  height: this.props.renderMobile? '350px' : '',}}>
                        <div className={styles.subCol} style={{top: '0px', height: '100%'}}>
                            <div className={styles.subtitle}>Schedule</div>
                            <div style={{position: 'absolute', top: '60px', left: '0px', height: 'calc(100% - 70px)', width: '100%', overflowY: 'scroll', overflowX: 'hidden'}}>
                                <div className={styles.about_schedule} style={{position: 'relative',}}><b>{`Next Week ${this.props.width < 940 && !this.props.renderMobile ? '' : `Features`}`}</b></div>
                                <div className={styles.about_name} style={{position: 'absolute', top: `${/*42 */ 60 + squish*1}px`}}>{names[0]}</div>
                                <div className={styles.about_schedule} style={{top: `${120 + squish*2}px`}}> <b>Coming Up...</b></div>
                                <div className={styles.about_name} style={{top: `${170 + squish*3}px`}}>{names[1]}</div>
                                <div className={styles.about_name} style={{top: `${220 + squish*4}px`}}>{names[2]}</div>
                                <div className={styles.about_name} style={{top: `${270 + squish*5}px`}}>{names[3]}</div>
                            </div>
                        </div>
                    </div>
                    <div id='submit_admin' style={{display: 'none'}}></div>
                    <div className={styles.col} style={{marginRight: '0px', overflowY: 'scroll', height: this.props.renderMobile? '400px' : ''}}>
                        <div className={styles.subtitle}>Recordings</div>
                        <div className={styles.about} style={{position: 'relative', top: '50px'}}>Listen to a podcast below, or download it to listen later (just click the blue arrow)! Scroll this box to view more.</div>
                        
                        <div className={styles.grid}>

                            { this.state.podcasts.map((podcast)=>{
                                let dash = podcast.name.indexOf('-');
                                return (
                                    <div key={podcast.name} className={styles.row}>
                                        <div className={styles.name}>{podcast.name.substring(0, dash - 1)}</div>
                                        <div className={styles.date}>{podcast.name.substring(dash + 2)}</div>
                                        <img className={styles.download} alt='download' src={staticImages.DOWNLOAD} onClick={()=>this.download(`${this.state.cdn}${podcast.key}`, podcast.name, podcast.key)}/>
                                        <audio src={`${this.state.cdn}${podcast.key}`} type="audio/mp3" download={podcast.name} className={styles.audio} controls controlsList="nodownload"/>
                                    </div>
                            )})}

                            <div className={styles.emptyRow}/>
                        
                        </div>

                    </div>
                    
                    <div className={styles.subCol} style={{position: 'relative', left: this.props.renderMobile? 'auto' : '0px', width: '300px', display: this.props.renderMobile? '' : 'none' , height: '180px'}}>
                            <div className={styles.subtitle} style={{top: '10px', fontSize: '23px'}}>Questions & Suggestions</div>
                            <div style={{position: 'absolute', top: '50px', left: '0px', width: '100%', height: 'calc(100% - 60px)', overflow: 'scroll',}}>
                                <div className={styles.q_container} style={{width, gridRowGap: '25px'}}>
                                    <div className={styles.about_question} style={{width: '280px', justifySelf: 'center', left: '0px'}}>Press the button below to submit a question for the upcoming podcast!</div>
                                    {/* <div className={styles.button_container}> */}
                                    <div className={styles.question} style={{justifySelf: 'center', position: 'relative'}} onClick={()=>{this.setState({popup: 'question'})}}>Ask a Question!</div>
                                    {/* </div> */}
                                </div>
                            </div>
                    </div>

                    <div className={styles.subCol} style={{position: 'relative', height: this.props.renderMobile? '350px' : '', width: '300px', left: this.props.renderMobile? 'auto' : '0px',}}>
                            <div className={styles.subtitle} style={{top: '16px'}}>Announcements</div>
                            <div className={styles.announcementsContainer} style={{left: '0px'}}>
                                <strong>4/12/21</strong> Hey everyone! Check out Ellie's newest book by downloading it here via the blue button.
                                <div className={styles.announcementOne}>
                                    {/* <div className={styles.pdf} onClick={()=>this.setState({popup: 'pdf'})}>{staticImages.PDF}</div> */}
                                    <img className={styles.pdf} style={{width: '25px', height: '25px'}} alt="download" src={staticImages.DOWNLOAD} onClick={()=>this.download('', 'I Already Knew.pdf', '', true)}/>
                                    <div className={styles.announcementText}><em>I already knew</em>, by Ellie McKenna</div> 
                                </div>
                            </div>
                    </div>

                    

                    <img className={styles.logo} alt="logo" src={staticImages.LOGO_NEW} style={{ left: 'auto', justifySelf: 'center',  position: 'relative', zIndex: this.props.renderMobile? 5 : '', top: this.props.renderMobile? 'auto' : '', display: this.props.renderMobile? 'flex' : 'none', bottom: '10px'}}/>

                </div>
                </div>

            :
            <div style={{overflow: this.props.renderMobile ? 'scroll' : 'hidden',}}>
                <div className={styles.title} style={{left: this.props.renderMobile? '' : `${titleLeft}px`, fontSize: this.props.renderMobile? `${Math.max(25, titleSize)}px` : `${titleSize}px`, width: this.props.renderMobile? '100%' : `${this.props.width - titleLeft}px`, zIndex: this.props.renderMobile? 5 : '', top: this.props.renderMobile? '43px' : '' }}>Casaletto Conversations</div>
                <img className={styles.mountains} draggable={false} style={{position: 'absolute', top: '0px', width: `${this.props.width}px`, height: `${this.props.height}px`, display: this.props.renderMobile? 'none' : ''}}  alt="clouds" src={staticImages.CLOUDS} />
                <img className={styles.logo} alt="logo" src={staticImages.LOGO_NEW} style={{zIndex: this.props.renderMobile? 5 : '', top: this.props.renderMobile? 'auto' : '', display: this.props.renderMobile? 'none' : ''}}/>
                
                <div className={this.props.renderMobile? styles.mainGrid : styles.flexContainer}>
                    {
                        this.props.renderMobile?  <div/> : null
                    }
                    <div className={styles.emptyCol} style={{left: this.props.renderMobile? 'auto' : '0px', background: 'none',}} id="leftCol">
                        
                        <div className={styles.subCol} style={{top: '0px', height: this.props.renderMobile? '310px' : '100%', overflow: 'hidden'}}>
                        { !aboutOrSchedule ?
                                <>
                                    <div className={styles.subtitle}>Quick Info</div>
                                        <div className={styles.button_container_quick}>
                                            <div className={styles.quick_button} onClick={()=>this.setState({aboutOrSchedule: 'about'})}>About</div>
                                            <div className={styles.quick_button} onClick={()=>this.setState({aboutOrSchedule: 'schedule'})}>Schedule</div>
                                            <div className={styles.quick_button} onClick={()=>this.setState({popup: 'question'})}>Questions / Suggestions</div>
                                            <div className={styles.quick_button} style={{width: '100%', height: '5px', boxShadow: 'none', backgroundColor: 'transparent', margin: '0'}}/>
                                    </div>
                                </>
                            :
                                aboutOrSchedule === 'about'?
                                    <>
                                        <div className={styles.backArrow} onClick={()=>this.setState({aboutOrSchedule: ''})}>{staticImages.BACK_ARROW} Back</div>
                                        <div className={styles.subtitle}>About</div>
                                        <div className={styles.about} style={{lineHeight: '24px', height: 'calc(100% - 70px)', overflow: 'scroll'}}>This website gives all the information for our Family Podcast. The purpose of this podcast is to bring our family together and keep everyone updated while we cannot see one another during this pandemic. These episodes have been recorded and edited by Sara Casaletto.</div>
                                    </>
                            : 
                                    <>
                                        <div className={styles.subtitle}>Schedule</div>
                                        <div className={styles.backArrow} onClick={()=>this.setState({aboutOrSchedule: ''})}>{staticImages.BACK_ARROW} Back</div>
                                        <div style={{position: 'absolute', top: '60px', left: '0px', height: 'calc(100% - 70px)', width: '100%', overflowY: 'scroll', overflowX: 'hidden'}}>
                                            <div className={styles.about_schedule} style={{position: 'relative',}}><b>{`Next Week ${this.props.width < 940 && !this.props.renderMobile ? '' : `Features`}`}</b></div>
                                            <div className={styles.about_name} style={{position: 'absolute', top: `${/*42 */ 60 + squish*1}px`}}>{names[0]}</div>
                                            <div className={styles.about_schedule} style={{top: `${120 + squish*2}px`}}> <b>Coming Up...</b></div>
                                            <div className={styles.about_name} style={{top: `${170 + squish*3}px`}}>{names[1]}</div>
                                            <div className={styles.about_name} style={{top: `${220 + squish*4}px`}}>{names[2]}</div>
                                            <div className={styles.about_name} style={{top: `${270 + squish*5}px`}}>{names[3]}</div>
                                        </div>
                                    </>
                        }
                        </div>
                        
                    </div>

                    <div className={styles.subCol} style={{width: '40%', top: announcementsTop, left: this.props.renderMobile? 'auto' : '0px', bottom: this.props.renderMobile? '-100px' : '0px', display: this.props.renderMobile? 'none' : '' ,}}>
                            <div className={styles.subtitle} style={{top: '16px'}}>Announcements</div>
                            <div className={styles.announcementsContainer} style={{left: '0px'}}>
                                <strong>4/12/21</strong> Hey everyone! Check out Ellie's newest book by downloading it here (blue button) or previewing the pdf (red button). You can resize the preview window by dragging the bottom right corner.
                                <div className={styles.announcementOne}>
                                    <div className={styles.pdf} onClick={()=>this.setState({popup: 'pdf'})}>{staticImages.PDF}</div>
                                    <img className={styles.pdf} style={{width: '25px', height: '25px'}} alt="download" src={staticImages.DOWNLOAD} onClick={()=>this.download('', 'I Already Knew.pdf', '', true)}/>
                                    <div className={styles.announcementText}><em>I already knew</em>, by Ellie McKenna</div> 
                                </div>
                            </div>
                    </div>

                    <div id='submit_admin' style={{display: 'none'}}></div>
                    <div className={styles.podcastCol} style={{overflowY: 'scroll', height: this.props.renderMobile? '400px' : ''}}>
                        <div className={styles.subtitle}>Recordings</div>
                        <div className={styles.about} style={{position: 'relative', top: '50px'}}>Listen to a podcast below, or download it to listen later (just click the blue arrow)! Scroll this box to view more.</div>
                        
                        <div className={styles.grid}>

                            { this.state.podcasts.map((podcast)=>{
                                let dash = podcast.name.indexOf('-');
                                return (
                                    <div key={podcast.name} className={styles.row}>
                                        <div className={styles.name}>{podcast.name.substring(0, dash - 1)}</div>
                                        <div className={styles.date}>{podcast.name.substring(dash + 2)}</div>
                                        <img className={styles.download} alt='download' src={staticImages.DOWNLOAD} onClick={()=>this.download(`${this.state.cdn}${podcast.key}`, podcast.name, podcast.key)}/>
                                        <audio src={`${this.state.cdn}${podcast.key}`} type="audio/mp3" download={podcast.name} className={styles.audio} controls controlsList="nodownload"/>
                                    </div>
                            )})}

                            <div className={styles.emptyRow}/>
                        
                        </div>

                    </div>
                    
                    <div className={styles.subCol} style={{position: 'relative', left: this.props.renderMobile? 'auto' : '0px', width: '300px', display: this.props.renderMobile? '' : 'none' , height: '180px'}}>
                            <div className={styles.subtitle} style={{top: '10px', fontSize: '23px'}}>Questions & Suggestions</div>
                            <div style={{position: 'absolute', top: '50px', left: '0px', width: '100%', height: 'calc(100% - 60px)', overflow: 'scroll',}}>
                                <div className={styles.q_container} style={{width, gridRowGap: '25px'}}>
                                    <div className={styles.about_question} style={{width: '280px', justifySelf: 'center', left: '0px'}}>Press the button below to submit a question for the upcoming podcast!</div>
                                    {/* <div className={styles.button_container}> */}
                                    <div className={styles.question} style={{justifySelf: 'center', position: 'relative'}} onClick={()=>{this.setState({popup: 'question'})}}>Ask a Question!</div>
                                    {/* </div> */}
                                </div>
                            </div>
                    </div>

                    <img className={styles.logo} alt="logo" src={staticImages.LOGO_NEW} style={{ left: 'auto', justifySelf: 'center',  position: 'relative', zIndex: this.props.renderMobile? 5 : '', top: this.props.renderMobile? 'auto' : '', display: this.props.renderMobile? 'flex' : 'none'}}/>

                </div>
                </div>
             }

                { this.state.popup === 'question' ? 
                    <div style={{height: this.props.height, width: this.props.width, overflow: 'hidden'}}>
                        <Layer style={{background: 'none', overflow: 'hidden'}} onEsc={(e)=>this.submitQ(e.target.value)} onClickOutside={(e)=>this.submitQ(e.target.value)} responsive={false} >
                        <div id={styles.outer} >
                            <div id={styles.exit} alt="exit" onClick={(e)=>this.submitQ(e.target.value)}>{staticImages.CLOSE}</div>
                            <div id={styles.title}>Questions or Suggestions</div>
                            <div id={styles.description}>What question(s) do you have for the next podcast? Leave your name in the big text box if you'd like a reply.</div>
                            <br></br>
                            {/* <div id={styles.description} style={{top: '120px'}}>Suggestions are also welcome.</div> */}
                            <input id='for' autoComplete='off' className={styles.popup_input} style={{top: '150px', height: '20px', width: '250px', fontSize: this.props.renderMobile ? '16px' : ''}}
                                placeholder={q_forPlaceholder}
                                onMouseOver={()=>{if (!this.state.q_for_editing) document.getElementById('for').placeholder = "Click to start editing"}}
                                onBlur={()=>{document.getElementById('for').placeholder = q_forPlaceholder; this.setState({q_for_editing: false});}}
                                onMouseLeave={()=>{document.getElementById('for').placeholder = q_forPlaceholder}}
                                onFocus={()=>{document.getElementById('for').placeholder = q_forPlaceholder; this.setState({q_for_editing: true})}}
                            />
                            <textarea wrap="hard" id='desc' autoComplete='off' className={styles.popup_input} style={{fontSize: this.props.renderMobile ? '16px' : ''}}
                                placeholder={descriptionPlaceholder}
                                onMouseOver={()=>{if (!this.state.desc_editing) document.getElementById('desc').placeholder = "Click to start editing"}}
                                onBlur={()=>{document.getElementById('desc').placeholder = descriptionPlaceholder; this.setState({desc_editing: false});}}
                                onMouseLeave={()=>{document.getElementById('desc').placeholder = descriptionPlaceholder}}
                                onFocus={()=>{document.getElementById('desc').placeholder = descriptionPlaceholder; this.setState({desc_editing: true})}}
                            />
                            <div id={styles.submit_button} onClick={(e)=>this.submitQ(true)}>
                                    Submit
                            </div>
                            <div id='warning' className={styles.warning}></div>
                    </div>
                        </Layer>
                    </div>
                :null}

                { this.state.popup === 'pdf' && (this.state.pdf || true) ?
                 <div style={{height: this.props.height, width: this.props.width, overflow: 'hidden'}}>
                    <Layer responsive style={{background: 'none'}} onEsc={()=>this.setState({popup: ''})} onClickOutside={()=>this.setState({popup: ''})} onClose={()=>this.setState({popup: ''})}>
                        <iframe title='I Already Knew by Ellie McKenna' className={styles.pdf_container} src={this.state.pdf}/>
                    </Layer> 
                 </div>
                 : null
                }

            </>
        )
    }
}

export default FamilyPocast;


    // componentDidMount() {
    //     var {podcasts} = this.state;
    //     var podcast = document.createElement("audio");
    //     podcast.src = Debbie;
    //     podcast.load();
    //     podcast.controls = true

    //     podcasts['Debbie'] = podcast;
    //     this.setState({podcasts, test: podcast});
    // }

    // play(name) {
    //     console.log(name)
    //     var {podcasts} = this.state;
    //     var podcast = podcasts[name];
    //     podcast.load();
    //     podcast.play();
    //     podcast.controls = true

    //     console.log(podcast.duration)
    //     console.log(podcasts)

    //     this.setState({'playing': podcast});
    // }