import React from 'react'
import FamilyPodcast from './FamilyPodcast/FamilyPodcast'

class App extends React.Component{
	constructor(props) {
        super(props)
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
        }

        this.updateWindow = this.updateWindow.bind(this);
	}
	
    updateWindow() {
      // this.forceUpdate();
      if ( Math.abs(window.innerHeight - this.state.height) > 5 || Math.abs(window.innerWidth - this.state.width) > 5) {
          this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateWindow);
    }


    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindow)
	}
	
  render() { 
    return(
      <div>

         <FamilyPodcast height={this.state.height} width={this.state.width} mobile={1100} renderMobile={window.innerWidth < 1100}/>

      </div>
    )
  };
}

export default App;
